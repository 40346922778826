import {noop} from '@/utils/functions';

export const useCodePushSyncQuery = () => noop;

export const useCodePushApplyMutation = () => {
  return {
    applyUpdate: noop,
    isLoading: false,
  };
};
