import {useState} from 'react';

import {useCodePushSyncQuery} from '@/queries/codepush';

export const useAppUpdateToast = () => {
  const [isHidden, setIsHidden] = useState(false);
  const {isUpdateAvailable} = useCodePushSyncQuery();

  return {
    showAppUpdateToast: isUpdateAvailable && !isHidden,
    closeAppUpdateToast: () => setIsHidden(true),
  };
};
